import { CheckoutStore } from "stores/checkout.store";
import { formatCurrencyToShortForm } from "./currency";
import {
  CheckoutSteps,
  ICheckoutOptionPayload,
  Product,
  TProducts,
  TPaymentDetails,
  EventCategories,
  CheckoutEvents,
  CommonTrackingData,
  EventActions,
  EventLabels,
  ButtonEventCategory,
  GenericEvents,
  VoucherEventCategory,
  LoginEvents,
  LoginTypes,
  PaymentEvents,
} from "./dataLayerEvents.types";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const VAT = {
  fr: 0.2,
  de: 0.19,
  it: 0.22,
  nl: 0.21,
  pl: 0.23,
  ru: 0,
  sl: 0.22,
  es: 0.21,
  sv: 0.25,
  en: 0.21,
  default: 0.23,
};

export const createProducts = (
  name: string,
  id: string,
  vinHashed: boolean,
  list: string,
  price: string,
  position: number
): Product[] => {
  const products = [];

  products.push(createProduct(name, id, vinHashed, list, price, position));

  return products;
};

export const createPayload = (
  step: number,
  products: TProducts | Product[],
  currency: string,
  paymentOption?: string
) => ({
  currencyCode: formatCurrencyToShortForm(currency),
  checkout: {
    actionField: {
      step,
      option: paymentOption || "",
    },
    products,
  },
});

export const createCheckoutOptionPayload = (currencyCode: string, paymentOption: string): ICheckoutOptionPayload => ({
  currencyCode,
  checkout: {
    actionField: {
      step: CheckoutSteps.PAYMENT_SELECTION,
      option: paymentOption || "",
    },
  },
});

export const createTransactionPayload = (
  products: Product[],
  currency: string,
  paymentDetails: TPaymentDetails,
  lang: string
) => {
  const revenue: number = parseFloat(paymentDetails?.revenue);
  return {
    currencyCode: formatCurrencyToShortForm(currency),
    purchase: {
      actionField: {
        id: paymentDetails.id,
        revenue: paymentDetails?.revenue,
        tax: (revenue * (VAT[lang] ? VAT[lang] : VAT.default)).toFixed(2).toString(),
        coupon: paymentDetails.coupon,
      },
      products,
    },
  };
};

const submitGtagEcommerceEvent = (event: string, checkoutStore: CheckoutStore, step: number): void => {
  const payload = createPayload(
    step,
    createProducts(
      checkoutStore.product.id,
      checkoutStore.recordCheck.isEncrypted
        ? checkoutStore.recordCheck.encryptedQuery
        : checkoutStore.recordCheck.query,
      checkoutStore.recordCheck.isEncrypted,
      checkoutStore.recordCheck.makeAndModel,
      checkoutStore.product.price.toString(),
      checkoutStore.product.position
    ),
    checkoutStore.currency
  );
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ecommerce: payload,
  });
};

const submitGtagCheckoutOptionEvent = (checkoutOptionPayload: ICheckoutOptionPayload): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: CheckoutEvents.CHECKOUT_STEP,
    ecommerce: checkoutOptionPayload,
  });
};

const submitGtagTransactionEvent = (
  commonData: CommonTrackingData,
  label: string,
  checkoutStore: any,
  transactionId: string,
  lang: string
): void => {
  const paymentDetails = {
    id: transactionId,
    revenue: checkoutStore.totalPrice.toString(),
    coupon: checkoutStore.product.voucherCode,
  };
  const transactionPayload = createTransactionPayload(
    createProducts(
      checkoutStore.product.id,
      checkoutStore.recordCheck.isEncrypted
        ? checkoutStore.recordCheck.encryptedQuery
        : checkoutStore.recordCheck.query,
      checkoutStore.recordCheck.isEncrypted,
      checkoutStore.recordCheck.makeAndModel,
      checkoutStore.product.price.toString(),
      checkoutStore.product.position
    ),
    checkoutStore.currency,
    paymentDetails,
    lang
  );
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...commonData,
    event: CheckoutEvents.CHECKOUT_TRANSACTION,
    category: EventCategories.ECOMMERCE,
    action: EventActions.REPORT_PURCHASE,
    label,
    ecommerce: transactionPayload,
  });
};
const submitGtagEvent = (action: string, category: string, label: string, location?: string): void => {
  const locationLabel = location ? ` | from ${location}` : "";
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "event",
    eventCategory: category,
    eventAction: action + locationLabel,
    eventLabel: label || "",
    vinValid: action === EventActions.SUCCESS,
  });
};

const submitRedirectEvent = (documentLocation: string): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "pageRedirect",
    documentLocation,
  });
};

export const createProduct = (
  name: string,
  id: string,
  vinHashed: boolean,
  list: string,
  price: string,
  position?: number
): Product => {
  return {
    name,
    id,
    vinHashed,
    list,
    price,
    position,
  };
};

export const createSingleReportProduct = (): Product => {
  return {
    name: "single",
    id: "unknown",
    vinHashed: undefined,
    list: undefined,
    price: undefined,
    position: 0,
  };
};

const submitProductImpressionEvent = (
  commonData: CommonTrackingData,
  currencyCode: string,
  impressions: Product[]
): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...commonData,
    event: CheckoutEvents.PRODUCT_IMPRESSION,
    ecommerce: {
      currencyCode: currencyCode,
      impressions,
    },
  });
};

const submitRedeemCreditReportPurchaseEvent = (commonData: CommonTrackingData, value: number): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...commonData,
    event: "event",
    category: EventCategories.ECOMMERCE,
    action: EventActions.REPORT_PURCHASE,
    label: EventLabels.REDEEM_CREDIT,
    value,
  });
};

const submitProductDetailEvent = (
  commonData: CommonTrackingData,
  currencyCode: string,
  query: string,
  vinHashed: boolean,
  totalAmount: string,
  makeAndModel: string
): void => {
  const productDetail = createProduct("Reports", query, vinHashed, makeAndModel, totalAmount);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...commonData,
    event: CheckoutEvents.PRODUCT_DETAIL,
    ecommerce: {
      currencyCode: currencyCode,
      detail: productDetail,
    },
  });
};

const submitProductClickEvent = (
  commonData: CommonTrackingData,
  currencyCode: string,
  makeAndModel: string,
  products: Product[]
): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...commonData,
    event: CheckoutEvents.PRODUCT_CLICK,
    ecommerce: {
      currencyCode: currencyCode,
      click: {
        actionField: { list: makeAndModel },
        products,
      },
    },
  });
};

const submitGenericButtonClickEvent = (pageType: string, clickText: string, category: ButtonEventCategory): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GenericEvents.GENERIC_BUTTON,
    eventCategory: category,
    eventAction: pageType,
    eventLabel: clickText,
  });
};

const submitPreviewPagePackagesButtonClickEvent = (): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GenericEvents.GENERIC_BUTTON,
    eventCategory: ButtonEventCategory.BUTTON,
    eventAction: "Preview-Page-Switch_Packages",
    eventLabel: "b2b_b2c_switch",
  });
};

const submitGenericButtonVoucherEvent = (voucherCode: string, voucherDiscountPercent: number): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: GenericEvents.GENERIC_BUTTON,
    eventCategory: VoucherEventCategory.VOUCHER_CODE,
    eventAction: voucherCode,
    eventLabel: voucherDiscountPercent,
  });
};

const submitSocialLoginEvent = (socialLoginService: string): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: LoginEvents.SOCIAL_LOGIN,
    loginType: LoginTypes.SOCIAL_LOGIN,
    loginService: socialLoginService,
  });
};

const submitPaymentMethodErrorEvent = (paymentMethod: string, product: string, errorMessage: string): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: PaymentEvents.PAYMENT_ERROR_VIEWED_EVENT,
    eventCategory: paymentMethod,
    eventAction: product,
    eventLabel: errorMessage,
  });
};

export const dataLayerEvents = {
  submitGtagEcommerceEvent,
  submitProductDetailEvent,
  submitProductClickEvent,
  submitGtagTransactionEvent,
  submitRedeemCreditReportPurchaseEvent,
  submitGtagEvent,
  submitRedirectEvent,
  submitProductImpressionEvent,
  submitGtagCheckoutOptionEvent,
  submitGenericButtonClickEvent,
  submitGenericButtonVoucherEvent,
  submitPreviewPagePackagesButtonClickEvent,
  submitSocialLoginEvent,
  submitPaymentMethodErrorEvent,
};
